* {
  margin: 0;
  padding: 0;
}
body {
  position: relative;
}
html {
  font-size: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.flex-column{
  flex-direction: column;
}
.flex-1{
  flex: 1;
}

.form-floating.is-invalid > label {
  color: #dc3545;
}

.pay-btn, .pay-btn:hover, .pay-btn:active, .pay-btn:focus {
  width: 100%;
  background: #6930cb;
  padding: 12px 0;
  font-size: 14px;
}

section.payment {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  margin: auto;
  background: #f5f5f5;
}
@media screen and (min-width: 768px) {
  section.payment {
    max-width: 400px;
  }
}
section.payment header {
  background: #000;
  color: #fff;
  position: relative;
  padding: 14px;
}
section.payment header h3 {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}
section.payment header button {
  background: transparent;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  border: 0;
  color: #fff;
  line-height: 0;
  font-size: 16px;
}
section.payment .shopname {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 6px 0px;
}
section.payment .shopname .backicon {
  font-size: 32px;
}
section.payment .shopname .shopicon {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  color: #6930cb;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.payment .shopname .title {
  color: #6930cb;
  flex: 1;
  overflow: hidden;
}
section.payment .shopname .title h3 {
  font-size: 16px;
  font-weight: 400;
}
section.payment .shopname .title p {
  font-size: 21px;
  font-weight: 400;
}
section.payment .payment-option {
  padding: 0 12px;
}
section.payment .payment-option .accordion-button,
section.payment .payment-option .accordion-item {
  background: transparent;
}
section.payment .payment-option .accordion-button {
  color: #6930cb;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #000;
}
section.payment .payment-option .accordion-button .icon-btn {
  background: #dfd1fa;
  color: #6930cb;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  display: flex;
  line-height: 0;
  font-size: 26px;
}
section.payment .payment-option .accordion-body .card {
  padding: 12px;
}
section.payment .payment-option .accordion-body .card .icons-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
section.payment .payment-option .accordion-body .card .icons-wrap .icon {
  width: 100%;
  height: 60px;
  background: #fff;
  border-radius: 8px;
  border: #ccc 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 16px;
}
section.payment .payment-option .accordion-body .card .icons-wrap .icon img {
  /* width: 32px; */
  height: 28px;
}

section.payment .payment-option .accordion-body .card .icons-wrap .icon i {
  font-size: 16px;
}

section.payment .payment-option .accordion-body .card .icons-wrap .icon.active {
 border: 3px solid #12e4eb38;
}

section.payment .payment-option .btn {
  border: 0;
  outline: 0;
  background-color: #6930cb;
  color: #fff;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
}
section.payment .payment-option .copy-upi {
  padding: 20px 0;
}

section.payment .payment-option .copy-upi h4 {
  font-size: 16px;
  margin-bottom: 20px;
}
section.payment .payment-option .copy-upi h4 span {
  color: red;
}
section.payment .payment-option .copy-upi .copy-upi-card {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 8px;
  padding: 10px;
}
section.payment .payment-option .copy-upi h5 {
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}
section.payment .payment-option .copy-upi h5 span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  background-color: #6930cb;
  color: #fff;
  border-radius: 50%;
  
}
section.payment .payment-option .copy-upi .copy-upi-item {
  border: 1px solid rgba(0,0,0,.1);
  background-color: #fff;
  border-radius: 4px;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 42px;
}
section.payment .payment-option .copy-upi .copy-upi-item .upi {
  font-size: 14px;
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
section.payment .payment-option .copy-upi .copy-upi-item .upi span {
  font-weight: bold;
}
section.payment .payment-option .copy-upi .copy-upi-item .btn {
  padding: 0 8px;
  border-radius: 0;
  height: 100%;
}
section.payment .payment-option .copy-upi .copy-upi-item input {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  border: 0;
  outline: 0;
}
section.payment .payment-option .copy-upi .submit-btn {
  width: 100%;
  margin-top: 36px;
  padding: 10px 12px;
}

section.payment .payment-option .qrcode .qrcode-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  gap: 20px;
}
section.payment .payment-option .qrcode .qr_code_img {
  width: 140px;
}
section.payment .payment-option .qrcode .qrcode-tip {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  color: #c2110d;
}
section.payment .payment-option .payment-video {
  padding-bottom: 100px;
}
section.payment .payment-option .payment-video-box {
  border-radius: 8px;
  overflow: hidden;
}
section.payment .payment-option .payment-video-box video {
  width: 100%;
}

.mt1{
  margin-top: 20px;
}
.mb1{
  margin-bottom: 10px;
}
.py1{
  padding-top: 20px;
  padding-bottom: 20px;
}

.ga-1{
  gap: 20px;
}

.dialog-pull-page {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.countdown-box {
  padding: 20px 15px;
  width: 100%;
  background-color: #fff;
  position: relative;
  padding-bottom: calc(20px + constant(safe-area-inset-bottom));
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
}
.closeicon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 32px;
  font-size: bold;
}
.payment-tip-title {
  font-size: 17px;
  font-size: bold;
}
.payment-tip-text {
  font-size: 14px;
  margin-top: 3px;
}
.payment-tip-app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 88%;
  margin: 30px auto;
  font-size: 14px;
  color: rgb(58, 56, 56);
}
.payment-app-icon {
  height: auto;
  width: 30px;
  margin-right: 10px;
}
.payment-tip-mins {
  color: rgb(43, 162, 69);
  font-size: 28px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}
.payment-tip-mins span {
  color: rgb(58, 56, 56);
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
}
.payment-tip-last {
  color: #000;
  opacity: 0.6;
  font-size: 13px;
  width: 90%;
  text-align: center;
  margin-top: 30px;
}
.payment-cancel-title {
  font-size: 13px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
}
.payment-cancel-tips {
  font-size: 12px;
  color: rgb(110, 110, 110);
  margin: 30px 20px;
}
.payment-cancel-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  margin: 30px 20px;
}

.cancel-buttons {
  padding: 5px 0;
  width: 48%;
  border: 2px solid rgb(105, 49, 202);
  color: rgb(105, 49, 202);
  border-radius: 8px;
  text-align: center;
}
.no-cancel-buttons {
  padding: 5px 0;
  width: 48%;
  border: 2px solid rgb(105, 49, 202);
  color: #fff;
  background-color: rgb(105, 49, 202);
  border-radius: 8px;
  text-align: center;
}
.payment-status-text {
  width: 100%;
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
}
.payment-status-icon {
  text-align: center;
  color: rgb(105, 49, 202);
  font-size: 50px;
  margin-top: 20px;
}
.payment-status-last {
  margin-top: 10px;
  text-align: center;
  opacity: 0.7;
}
.payment-status-button {
  width: 100%;
  padding: 5px 0;
  border: 2px solid rgb(105, 49, 202);
  color: #fff;
  background-color: rgb(105, 49, 202);
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}
.payment-status-icon span {
  color: red !important;
}

